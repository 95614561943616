export default [
  {
    url: '/',
    name: "Dashboard",
    icon: "HomeIcon",
    slug: "dashboard"
  },
  {
    url: null,
    name: "Orders",
    icon: "ShoppingCartIcon",
    submenu: [
      {
        url: '/orders?status=draft',
        name: "Draft",
        slug: "orders",
      },
      {
        url: '/orders?status=pending',
        name: "Pending",
        slug: "orders",
      },
      {
        url: '/orders?status=delivered',
        name: "Delivered",
        slug: "orders",
      },
      {
        url: '/orders?status=completed',
        name: "Completed",
        slug: "orders",
      },
      {
        url: '/orders?status=refunded',
        name: "Refunded",
        slug: "orders",
      },
    ]
  },
  {
    url: "/categories",
    name: "Categories",
    slug: "categories",
    icon: "ListIcon",
  },
  {
    url: "/products",
    name: "Products",
    slug: "products",
    icon: "PackageIcon",
  },
  {
    url: '/users',
    name: "Users",
    icon: "UserIcon",
    slug: "user-list",
    submenu: [
      {
        url: '/users/create',
        name: "Add New",
        slug: "user-create",
      },
      {
        url: '/users?type=buyer',
        name: "Buyers",
        slug: "user-list",
      },
      {
        url: '/users?type=admin',
        name: "Admin",
        slug: "user-list",
      },
    ]
  },
  {
    url: "/notifications",
    name: "Notifications",
    slug: "notifications",
    icon: "BellIcon",
  },
  {
    url: null,
    name: "Reviews",
    icon: "AwardIcon",
    submenu: [
      {
        url: '/reviews?is_real=1',
        name: "Real",
        slug: "reviews",
      },
      {
        url: '/reviews?is_real=0',
        name: "Flip",
        slug: "reviews",
      },
    ]
  },
  {
    url: null,
    name: "Settings",
    icon: "SettingsIcon",
    submenu: [
      {
        url: '/coupons',
        name: "Coupons",
        slug: "coupons",
      },
      {
        url: '/bulletins',
        name: "Bulletins",
        slug: "bulletins",
      },
    ]
  },
  {
    url: "/payments",
    name: "Payments",
    slug: "payments",
    icon: "ArchiveIcon",
  },
  {
    url: "/surveys",
    name: "Surveys",
    slug: "surveys",
    icon: "DatabaseIcon",
  },
  {
    url: "/inquiries",
    name: "Inquiries",
    slug: "inquiries",
    icon: "HelpCircleIcon",
  }
]

